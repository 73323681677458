import styled, { css } from 'styled-components';

import { Dropdown } from 'components/Toolkit/Dropdown/Dropdown';
import { InputButton } from 'components/Toolkit/InputButton/InputButton';

import { theme } from 'helpers/Theme';
import type {
  ContainerStyleProps,
  SecondarySelectContainerStyleProps,
} from 'features/location/Location.typed';

const Container = styled.div<ContainerStyleProps>`
  display: flex;
  width: 100%;
  flex-direction: ${({ displayInline }) => (displayInline ? 'column' : 'row')};
`;

const DropdownStyles = css`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const SDropdown = styled(Dropdown)<ContainerStyleProps>`
  width: 100%;
  margin-bottom: ${({ theme, displayInline }) =>
    displayInline ? theme.spacing.S8 : 0};
`;

const InputButtonWithSecondarySelect = styled.div<ContainerStyleProps>`
  display: flex;
  flex-direction: ${({ displayInline }) => (displayInline ? 'column' : 'row')};
`;

const SInputButton = styled(InputButton)<ContainerStyleProps>`
  border-top-right-radius: ${({ displayInline }) =>
    displayInline ? theme.borderRadius.default : 0};
  border-bottom-right-radius: ${({ displayInline }) =>
    displayInline ? theme.borderRadius.default : 0};
  margin-bottom: ${({ theme, displayInline }) =>
    displayInline ? theme.spacing.S8 : 0};
`;

const SecondarySelectContainer = styled.div<SecondarySelectContainerStyleProps>`
  display: flex;
  min-width: ${({ secondarySelectWidth }) => `${secondarySelectWidth}`};
`;

export {
  Container,
  DropdownStyles,
  SDropdown,
  InputButtonWithSecondarySelect,
  SInputButton,
  SecondarySelectContainer,
};
