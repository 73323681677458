const suggestedSearches = (baseUrl: string) => [
  {
    leftText: 'Cars from Trusted Dealerships',
    rightText: 'In Cars',
    url: `${baseUrl}/cars?source=trade`,
    searchRequest: {
      terms: '',
      sections: [''],
    },
  },
  {
    leftText: 'Cars with a Warranty',
    rightText: 'In Cars',
    url: `${baseUrl}/cars?warrantyDuration_from=3`,
    searchRequest: {
      terms: '',
      sections: [''],
    },
  },
  {
    leftText: 'Cars with Greenlight History Check',
    rightText: 'In Cars',
    url: `${baseUrl}/cars?verifications=greenlightVerified`,
    searchRequest: {
      terms: '',
      sections: [''],
    },
  },
  {
    leftText: 'Cars with Finance',
    rightText: 'In Cars',
    url: `${baseUrl}/cars?car-finance=yes`,
    searchRequest: {
      terms: '',
      sections: [''],
    },
  },
  {
    leftText: 'New Cars',
    rightText: 'In Cars',
    url: `${baseUrl}/cars?mileage_to=1000&year_from=${new Date().getFullYear()}`,
    searchRequest: {
      terms: '',
      sections: [''],
    },
  },
];

export { suggestedSearches };
