import { error, ok, Result } from 'domains/Result';

import { areasApi } from 'api/areasApi';
import { boldMatchingText } from 'helpers/formatting';
import { combineValues } from 'utils/CombineAndSeparateValues';

import type {
  AreasResponse,
  AutocompleteArea,
  TGetAutcompleteAreas,
  TGetCountyAreas,
} from 'api/types/areasApiTypes';
import type { Area } from 'domains/Area';

export interface IAreasDataSource {
  getCountyAreas: TGetCountyAreas;
  getAreasAutocomplete: TGetAutcompleteAreas;
}

interface IAreasRepository {
  getCountyAreas: (
    county: string,
    display: string,
  ) => Promise<Result<Error, Area[]>>;
  getAreasAutocomplete: (
    term: string,
    county?: string,
  ) => Promise<Result<Error, Area[]>>;
}

const AreasRepository = (
  areasDataSource: IAreasDataSource,
): IAreasRepository => {
  const GROUP_NAME = 'areas';

  const getCountyAreas: IAreasRepository['getCountyAreas'] = async (
    county: string,
    display: string,
  ) => {
    try {
      const { data } = await areasDataSource.getCountyAreas(county);
      const mappedAreas = mapCountyAreas(data, county, display);
      return ok(mappedAreas);
    } catch (err) {
      return error(new Error(err));
    }
  };

  const getAreasAutocomplete: IAreasRepository['getCountyAreas'] = async (
    term: string,
    county?: string,
  ) => {
    try {
      const { data } = await areasDataSource.getAreasAutocomplete(term, county);
      const mappedAreas = mapAutocompleteAreas(data.areas, term);
      return ok(mappedAreas);
    } catch (err) {
      return error(new Error(err));
    }
  };

  const mapCountyAreas = (
    areas: AreasResponse[],
    county: string,
    countyDisplayName: string,
  ): Area[] => {
    const mappedAreas = areas.map((area) => {
      return {
        value: combineValues([
          area.latitude,
          area.longitude,
          area.countyTown,
          county,
          countyDisplayName,
        ]),
        displayName: `${area.countyTown}, ${countyDisplayName}`,
        group: GROUP_NAME,
      };
    });
    return mappedAreas;
  };

  const mapAutocompleteAreas = (
    areas: AutocompleteArea[],
    term: string,
  ): Area[] => {
    const mappedAreas = areas.map((area) => {
      const formattedDisplayName = boldMatchingText(area.displayName, term);

      return {
        value: combineValues([
          area.latitude,
          area.longitude,
          area.countyTown,
          area.county,
          `Co. ${area.county}`,
        ]),
        displayName: area.displayName,
        group: GROUP_NAME,
        formattedDisplayName,
      };
    });
    return mappedAreas;
  };

  return {
    getCountyAreas,
    getAreasAutocomplete,
  };
};

export { AreasRepository };

export const areasRepository = AreasRepository({
  getCountyAreas: areasApi.getAreas,
  getAreasAutocomplete: areasApi.getAreasAutocomplete,
});
