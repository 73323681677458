import { useReducer } from 'react';
import type { DisplayWithValue } from 'components/HomePage/components/MotorSearch/MotorSearch.typed';
import type {
  UseRangeFilterReturnType,
  Reducer,
  State,
  Action,
  Filter,
} from 'components/HomePage/components/MotorSearch/RangeFilter/UseRangeFilter.hook.typed';

const reducer: Reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'FILTER_MAX_VALUES':
      const filteredValues = state.allMaxValues.filter(action.payload);
      return {
        ...state,
        maxValues: [state.maxValuePlaceholder, ...filteredValues],
      };
    case 'RESET_MAX_VALUES':
      return {
        ...state,
        maxValues: [state.maxValuePlaceholder, ...state.allMaxValues],
      };
    default:
      return state;
  }
};

export function useRangeFilter(
  searchFilterFrom: DisplayWithValue[],
  searchFilterTo: DisplayWithValue[],
): UseRangeFilterReturnType {
  const [maxValuePlaceholder, ...maxFilterValues] = searchFilterTo;
  const [minValuePlaceholder] = searchFilterFrom;

  const [state, dispatch] = useReducer(reducer, {
    allMaxValues: maxFilterValues,
    maxValues: searchFilterTo,
    minValues: searchFilterFrom,
    maxValuePlaceholder,
    minValuePlaceholder,
  });

  const filterMaxValuesPreservingPlaceholder = (filter: Filter) => {
    dispatch({ type: 'FILTER_MAX_VALUES', payload: filter });
  };

  const resetMaxValues = () => dispatch({ type: 'RESET_MAX_VALUES' });

  return [
    state.minValues,
    state.maxValues,
    filterMaxValuesPreservingPlaceholder,
    resetMaxValues,
  ];
}
