import { CustomSelect } from 'components/Toolkit/Inputs/CustomSelect';
import { ListSkeleton } from 'features/location/components/ListSkeleton/ListSkeleton';

import { useLocationContext } from 'features/location/Location.context';

import * as Styled from 'features/location/components/SearchInputAndPrimarySelect/SearchInputAndPrimarySelect.styled';

import type { SearchInputAndPrimarySelectProps } from 'features/location/components/SearchInputAndPrimarySelect/SearchInputAndPrimarySelect.typed';
import { LEVELS } from 'features/location/Location.typed';

function SearchInputAndPrimarySelect(props: SearchInputAndPrimarySelectProps) {
  const {
    skeletonListLength,
    autoFocus,
    isLocationBottomSheetOpen,
    isExpanded,
    onSelect,
  } = props;

  const { searchInput, areas, isLoading, level } = useLocationContext();

  return (
    <Styled.Container>
      {/* Render element based on "open" state to ensure autofocus is applied, 
      rather than applying it to an element rendered but not in view 
      which removes autofocus from that element on open */}
      {(isExpanded || isLocationBottomSheetOpen) && (
        <>
          <Styled.SSearchInput autoFocus={autoFocus} />
          {isLoading && <ListSkeleton arrayLength={skeletonListLength} />}
          {!isLoading && areas.length !== 0 && (
            <CustomSelect
              onSelect={onSelect}
              listData={areas}
              displayIcon={level === LEVELS.LEVEL_ONE}
              hasHeading={searchInput.length < 3}
            />
          )}
        </>
      )}
    </Styled.Container>
  );
}

export { SearchInputAndPrimarySelect };
