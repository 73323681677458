import { LEVELS } from 'features/location/Location.typed';
import type { CountyData } from 'features/location/Location.typed';
import {
  DEFAULT_PRESELECT,
  DEFAULT_COUNTY_TOWN_RADIUS,
} from 'features/location/Location.constants';
import { useLocationShared } from 'features/location/hooks/LocationShared/LocationShared.hook';
import { useLocationContext } from 'features/location/Location.context';
import { findRadiusByValue } from 'features/location/helpers';

const useLocationWriteState = () => {
  const { level, radiusList } = useLocationContext();

  const radius = findRadiusByValue(DEFAULT_COUNTY_TOWN_RADIUS, radiusList);

  const { handleCountySelect, handleCountyTownSelect, handleReset } =
    useLocationShared();

  const handleSelectListItem = (countyData: CountyData) => {
    switch (level) {
      case LEVELS.LEVEL_ONE:
        if (countyData.value === DEFAULT_PRESELECT.value) {
          handleReset(level);
        } else {
          handleCountySelect(countyData);
        }
        return;
      case LEVELS.LEVEL_TWO:
        if (countyData.value === DEFAULT_PRESELECT.value) {
          handleReset(level);
        } else {
          handleCountyTownSelect(countyData, radius);
        }
        return;
      default:
        return;
    }
  };

  return {
    handleSelectListItem,
  };
};

export { useLocationWriteState };
