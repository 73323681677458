import { pushToDataLayer } from 'services/gtmService';

interface HandlePushToDataLayerForLocationSearchArgs {
  county: string;
  countyTown: string | null;
  radius: string;
}

const handlePushToDataLayerForLocationSearch = (
  args: HandlePushToDataLayerForLocationSearchArgs,
) => {
  const { county, countyTown, radius } = args;
  const area = countyTown ? `${countyTown}, ${county}` : undefined;
  const distance = area ? `${radius} km` : undefined;

  pushToDataLayer({
    event: 'location_filter_applied',
    location_filter_county: county,
    location_filter_area: area,
    location_filter_distance: distance,
  });
};

export { handlePushToDataLayerForLocationSearch };
