import { useState, useEffect } from 'react';
import type { MouseEvent } from 'react';
import styled from 'styled-components';

import { Checkbox } from 'components/Toolkit/Inputs/Checkbox';

export interface ListItemProps {
  displayName: string;
  clickCallback: (t: boolean) => void;
  isDefault?: boolean;
  isReset: boolean;
  isPreSelected: boolean;
}

const SListItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_LIGHTER};
`;

const A11yButton = styled.button<{ isDefault?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.S8} ${theme.spacing.M16}`};
  background-color: ${({ theme }) => theme.colors.WHITE};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  width: 100%;
  height: 40px;
  text-align: left;
  margin: ${({ isDefault }) => (isDefault ? `0 0 1px` : `1px 0`)};
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.OFFWHITE};
  }
`;

const SCheckbox = styled(Checkbox)`
  pointer-events: none;
  min-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function ListItem({
  displayName,
  clickCallback,
  isDefault = false,
  isReset,
  isPreSelected,
}: ListItemProps) {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (isPreSelected) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [isPreSelected]);

  useEffect(() => {
    if (isReset) {
      setIsChecked(false);
    }
  }, [isReset]);

  function handleClick(event?: MouseEvent<HTMLButtonElement> | boolean) {
    typeof event !== 'boolean' && event && event.preventDefault();
    if (isChecked) {
      setIsChecked(false);
      clickCallback(false);
    } else {
      setIsChecked(true);
      clickCallback(true);
    }
  }

  return (
    <SListItem>
      <A11yButton isDefault={isDefault} onClick={handleClick}>
        {displayName}
        <SCheckbox
          checked={Boolean(isChecked)}
          onChange={() => undefined}
          willUseSubText={false}
        />
      </A11yButton>
    </SListItem>
  );
}

export { ListItem };
