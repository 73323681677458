import { handlePushToDataLayerForLocationSearch } from 'features/location/helpers/Tracking';

import type { useRadiusSelectParams } from 'features/location/hooks/RadiusSelect/RadiusSelect.hook.typed';

function useRadiusSelect(props: useRadiusSelectParams) {
  const { county, countyTown, setRadius, pushOnChange } = props;

  const handleRadiusChange = (radius: string) => {
    setRadius(radius);
    /* Push to data layer based on user event rather than change of value.
    Avoid triggering push event when radius changes from disabled state (+5km) 
    to enabled state (+25km) on county town select or user deselects county deselecting
    a county town, thereby updating radius value */
    if (pushOnChange && county) {
      handlePushToDataLayerForLocationSearch({
        county,
        countyTown,
        radius,
      });
    }
  };

  return { handleRadiusChange };
}

export { useRadiusSelect };
