import type { AxiosResponse } from 'axios';
import type { GetAutoCompleteResponse } from 'api/types/autoCompleteApiTypes';

import { donedealAutoCompleteGateway } from 'api/api-utils/commonApiUtils';
import { IncomingHttpHeaders } from 'http';
import { API_CLIENT_TIMEOUT } from 'utils';

async function getAutoCompleteValues(
  searchText: string,
  section?: string,
  headers: IncomingHttpHeaders = { accept: 'application/json' },
): Promise<AxiosResponse<GetAutoCompleteResponse>> {
  const response: AxiosResponse<GetAutoCompleteResponse> =
    await donedealAutoCompleteGateway.get(
      `/v2/autocomplete?text=${searchText}${
        section ? `&section=${section}` : ''
      }`,
      {
        headers: {
          ...headers,
          accept: 'application/json',
        },
        timeout: API_CLIENT_TIMEOUT,
      },
    );
  return response;
}

export const autoCompleteApi = { getAutoCompleteValues };
