import { ISizes } from 'components/Dashboard/styles/skeleton';
import { media } from 'helpers/breakpoints';
import { spacing } from 'helpers/genericStyles';
import styled, { css } from 'styled-components';

// TODO: find approrpiate place for these styles to be used everywhere
const PlaceholderElementCSS = css`
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.GREY_LIGHT};

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(242, 242, 242, 0) 0,
      rgba(242, 242, 242, 0.2) 20%,
      rgba(242, 242, 242, 0.5) 60%,
      rgba(242, 242, 242, 0)
    );
    animation: waveEffect 2s infinite;
    content: '';

    @keyframes waveEffect {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;

const BasicContainer = styled.div<ISizes>`
  ${spacing};
  width: ${({ mWidth }) => mWidth};
  height: ${({ mHeight }) => mHeight};
  border-radius: ${({ theme }) => theme.borderRadius.default};

  ${media.large} {
    width: ${({ width, mWidth }) => width ?? mWidth};
    height: ${({ height, mHeight }) => height ?? mHeight};
  }
  ${PlaceholderElementCSS};
`;

const List = styled.ul`
  background-color: #f2f2f2;
`;

const ListItem = styled.li`
  padding: ${({ theme }) => `${theme.spacing.S8} ${theme.spacing.M16}`};
  display: flex;
  background-color: #ffffff;
  height: 40px;
  margin: 0 0 1px;
`;

export { List, ListItem, BasicContainer };
