import type { ChangeEvent } from 'react';

import { SearchOutlineIc, CloseOutlineIc } from '@dsch/dd-icons';

import type { SearchInputProps } from 'features/location/components/SearchInput/SearchInput.typed';

import * as Styled from 'features/location/components/SearchInput/SearchInput.styled';
import { useLocationContext } from 'features/location/Location.context';

function SearchInput(props: SearchInputProps) {
  const { autoFocus, className } = props;

  const { searchInput, setSearchInput } = useLocationContext();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.currentTarget.value);
  };

  return (
    <Styled.SearchInputOuterContainer className={className}>
      <Styled.SearchInputInnerContainer>
        <SearchOutlineIc height={24} width={24} />
        <Styled.SearchInput
          placeholder="Type town, area or county"
          onChange={handleChange}
          value={searchInput}
          autoFocus={autoFocus}
          autoComplete="off"
          type="search"
        />
        {searchInput.length > 0 && (
          <Styled.SButton onClick={() => setSearchInput('')}>
            <CloseOutlineIc width={12} height={12} />
          </Styled.SButton>
        )}
      </Styled.SearchInputInnerContainer>
    </Styled.SearchInputOuterContainer>
  );
}

export { SearchInput };
