import type { ReactNode } from 'react';

import styled, { CSSProperties } from 'styled-components';

import { media } from 'helpers/breakpoints';

type DisplayContainerKeys = 'small' | 'medium' | 'large';

type DisplayContainerConfig = Record<
  DisplayContainerKeys,
  CSSProperties['display']
>;

type DisplayContainerProps = {
  className?: string;
  children: ReactNode;
  config: DisplayContainerConfig;
};

const Container = styled.div<{
  config: DisplayContainerConfig;
}>`
  display: ${({ config: { small } }) => small};

  ${media.medium} {
    display: ${({ config: { medium } }) => medium ?? 'none'};
  }

  ${media.large} {
    display: ${({ config: { large } }) => large ?? 'none'};
  }
`;

const DisplayContainer = (props: DisplayContainerProps) => {
  const { className, config, children } = props;

  return (
    <Container className={className} config={config}>
      {children}
    </Container>
  );
};

export { DisplayContainer };
export type { DisplayContainerConfig };
