import styled from 'styled-components';

import { SearchInput } from 'features/location/components/SearchInput/SearchInput';

const Container = styled.div`
  position: relative;
`;

const SSearchInput = styled(SearchInput)`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.WHITE};
`;

export { Container, SSearchInput };
