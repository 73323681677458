import { v4 as uuidv4 } from 'uuid';

import * as Skeleton from 'features/location/components/ListSkeleton/ListSkeleton.styled';

import type { ListSkeletonProps } from 'features/location/components/ListSkeleton/ListSkeleton.typed';

function ListSkeleton(props: ListSkeletonProps) {
  const { className, arrayLength } = props;

  return (
    <Skeleton.List className={className}>
      {Array.apply(null, Array(arrayLength)).map((_item: null) => {
        return (
          <Skeleton.ListItem key={uuidv4()}>
            <Skeleton.BasicContainer mWidth="105px" mHeight="24px" />
          </Skeleton.ListItem>
        );
      })}
    </Skeleton.List>
  );
}

export { ListSkeleton };
