import styled from 'styled-components';

const SearchInputOuterContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.S8};
`;

const SearchInputInnerContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.S8};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
  display: grid;
  grid-template-columns: 24px 1fr 24px;
  grid-gap: ${({ theme }) => theme.spacing.S8};
`;

const SearchInput = styled.input`
  outline: none;
  border: 0px;
  padding: 0px;
  width: 100%;
  background-color: transparent;
  ${({ theme }) => theme.fontSize.M16};
`;

const SButton = styled.button`
  color: ${({ theme }) => theme.colors.GREY_DARK};
  cursor: pointer;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
    color: ${({ theme }) => theme.colors.BLUE};
  }
`;

export {
  SearchInputOuterContainer,
  SearchInputInnerContainer,
  SearchInput,
  SButton,
};
