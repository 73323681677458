import { thousandSeparated } from 'helpers/formatting';
import type {
  GetDisplayValuesFromFilterValues,
  GetFiltersByName,
  GetFiltersFromSearchFilters,
  GetFilterValuesFromSearchFilters,
  GetMotorDisplayValuesFromFilterValues,
  GetMotorFiltersFromSearchFilters,
  Mapper,
  MotorOptionMapper,
} from 'components/HomePage/components/MotorSearch/MotorSearch.helpers.typed';
import { ListItem } from 'components/HomePage/components/MotorSearch/MotorSearch.typed';
import type {
  GetSearchFilterMakeModelItem,
  IGetSearchFilterMakeModel,
} from 'api/types/searchPageApiTypes';

const formatValue: Mapper = (value) => ({
  label: value.displayName,
  value: value.value.toString(),
});

const formatMotorOption: MotorOptionMapper = (value, index) => {
  return {
    id: index,
    ...value,
  };
};

const formatPrice: Mapper = (value) => ({
  label: `\u20AC${thousandSeparated(value.displayName)}`,
  value: value.value.toString(),
});

const getDisplayValuesFromFilterValues: GetDisplayValuesFromFilterValues = (
  filterValues = [],
  mapper = formatValue,
) => {
  const placeholder = {
    label: filterValues[0]?.displayName || '',
    value: 'placeholder',
  };
  const filterValuesOnly = filterValues.slice(1).map(mapper);
  return [placeholder, ...filterValuesOnly];
};

const getMotorDisplayValuesFromFilterValues: GetMotorDisplayValuesFromFilterValues =
  (filterValues = [], mapper) => {
    return filterValues.map(mapper);
  };
const getFiltersByName: GetFiltersByName = (searchFilters = [], name) =>
  searchFilters.find((filter) => filter.name === name);

const getFilterValuesFromSearchFilters: GetFilterValuesFromSearchFilters = (
  searchFilters = [],
  filterName,
  filterType,
) => {
  const filters = getFiltersByName(searchFilters, filterName);
  return filters?.values?.[0][filterType] || [];
};

const getYearOptions: GetFiltersFromSearchFilters = (searchFilters) => {
  const yearFiltersFrom = getFilterValuesFromSearchFilters(
    searchFilters,
    'year',
    'from',
  );
  const yearFiltersTo = getFilterValuesFromSearchFilters(
    searchFilters,
    'year',
    'to',
  );
  return [
    getDisplayValuesFromFilterValues(yearFiltersFrom, formatValue),
    getDisplayValuesFromFilterValues(yearFiltersTo, formatValue),
  ];
};

const getPriceOptions: GetFiltersFromSearchFilters = (searchFilters) => {
  const yearFiltersFrom = getFilterValuesFromSearchFilters(
    searchFilters,
    'price',
    'from',
  );
  const yearFiltersTo = getFilterValuesFromSearchFilters(
    searchFilters,
    'price',
    'to',
  );
  return [
    getDisplayValuesFromFilterValues(yearFiltersFrom, formatPrice),
    getDisplayValuesFromFilterValues(yearFiltersTo, formatPrice),
  ];
};

const getMakesOptions: GetMotorFiltersFromSearchFilters = (searchFilters) => {
  const makesOptions = getFilterValuesFromSearchFilters(
    searchFilters,
    'makeModel',
    'all',
  );
  const makesOptionValues = makesOptions.filter(
    ({ displayName }) => displayName !== 'Any',
  );

  return getMotorDisplayValuesFromFilterValues(
    makesOptionValues,
    formatMotorOption,
  );
};

const getPopularMakesOptions: GetMotorFiltersFromSearchFilters = (
  searchFilters,
) => {
  const makesOptions = getFilterValuesFromSearchFilters(
    searchFilters,
    'makeModel',
    'popular',
  );
  const makesOptionValues = makesOptions.filter(
    ({ displayName }) => displayName !== 'Any',
  );
  return getMotorDisplayValuesFromFilterValues(
    makesOptionValues,
    formatMotorOption,
  );
};

const filterModels = (modelsData?: GetSearchFilterMakeModelItem[]) =>
  (modelsData ?? []).filter(
    (item: GetSearchFilterMakeModelItem) => item.displayName !== 'Any',
  );

const getModelOptions = (modelData: IGetSearchFilterMakeModel): ListItem[] => {
  const { all, popular } = modelData;
  const allModels = filterModels(all);
  const popularModels = filterModels(popular);

  if (popularModels.length > 0) {
    return [
      { displayName: 'All Models', value: '' },
      { displayName: 'Popular', value: '' },
      ...popularModels,
      { displayName: 'Models list', value: '' },
      ...allModels,
    ];
  } else {
    return [
      { displayName: 'All Models', value: '' },
      { displayName: 'Models list', value: '' },
      ...allModels,
    ];
  }
};

export {
  getPriceOptions,
  getYearOptions,
  getMakesOptions,
  getPopularMakesOptions,
  getModelOptions,
};
